<template>
  <div>
    <a-row type="flex" :gutter="[24,24]">
      <a-col :span="16">
        <h5 class="font-semibold">热门交易员</h5>
        <p class="text-lg">
          精选全网来自币安排行榜、欧意牛人榜、币coin等平台的顶级交易员，收益各个平台可查。使用跟单猿，可0延迟跟单，同时享受0分润！
        </p>
      </a-col>
    </a-row>

    <!-- Project Cards -->
    <a-row type="flex" :gutter="24">
<!--      <a-col :span="8" class="mb-24">-->
        <!-- Project Card -->
<!--        <CardProject2-->
<!--            :vip=false-->
<!--            title="Bit浪浪"-->
<!--            :realSumMoney="getMoney('Bit浪浪')"-->
<!--            logo="images/head/bitlanglang.jpg"-->
<!--            :team="['images/bicoin.png']"-->
<!--        >-->
<!--        </CardProject2>-->
        <!-- / Project Card -->
<!--      </a-col>-->
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=true
            title="从小有个百万梦"
            :realSumMoney="getMoney('从小有个百万梦')"
            logo="images/head/baiwan.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=true
            title="Cryptoxn(隐毒素)"
            :realSumMoney="getMoney('Cryptoxn(隐毒素)')"
            logo="images/head/default-avatar.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="明明明宏"
            :realSumMoney="getMoney('明明明宏')"
            logo="images/head/minghong.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="马锝水"
            :realSumMoney="getMoney('马锝水')"
            logo="images/head/madeshui.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="一起打麻将"
            :realSumMoney="getMoney('一起打麻将')"
            logo="images/head/damajiang.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=true
            title="Cequirea(塞奎尔)"
            :realSumMoney="getMoney('Cequirea(塞奎尔)')"
            logo="images/head/default-avatar.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=true
            title="好银行"
            :realSumMoney="getMoney('好银行')"
            logo="images/head/haoyinhang.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=true
            title="0xPascom(私域)"
            :realSumMoney="getMoney('0xPascom(私域)')"
            logo="images/head/0xPascom.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="小狐先生"
            :realSumMoney="getMoney('小狐先生')"
            logo="images/head/xiaohuxiansheng.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="风火山林"
            :realSumMoney="getMoney('风火山林')"
            logo="images/head/fenghuoshanlin.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="unusual_gainz"
            :realSumMoney="getMoney('unusual_gainz')"
            logo="images/head/unusual_gainz.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="星辰社区"
            :realSumMoney="getMoney('星辰社区')"
            logo="images/head/default-avatar.png"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="O荒天蛙O"
            :realSumMoney="getMoney('O荒天蛙O')"
            logo="images/head/huangtianwa.png"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="万年"
            :realSumMoney="getMoney('万年')"
            logo="images/head/wannian.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="Fiee的逆袭之路"
            :realSumMoney="getMoney('Fiee的逆袭之路')"
            logo="images/head/fiee.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="龟兔赛跑985"-->
<!--            :realSumMoney="getMoney('龟兔赛跑985')"-->
<!--            logo="images/head/guitusaipao.jpg"-->
<!--            :team="['images/binance.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="轮回绕过谁"
            :realSumMoney="getMoney('轮回')"
            logo="images/head/lunhui.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="卡皮巴拉(老明星)"-->
<!--            :realSumMoney="getMoney('卡皮巴拉(老明星)')"-->
<!--            logo="images/head/laomingxing.jpg"-->
<!--            :team="['images/okx.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="太阳V"
            :realSumMoney="getMoney('太阳V')"
            logo="images/head/taiyang.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="水桥基金交易员"-->
<!--            :realSumMoney="getMoney('水桥基金交易员')"-->
<!--            logo="images/head/qiaoshui.png"-->
<!--            :team="['images/binance.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="明天以后"-->
<!--            :realSumMoney="getMoney('明天以后')"-->
<!--            logo="images/head/mingtianyihou.jpg"-->
<!--            :team="['images/bicoin.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="涵轩鹭"
            :realSumMoney="getMoney('涵轩鹭')"
            logo="images/head/hanxuanlu.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="Plymouth"
            :realSumMoney="getMoney('Plymouth')"
            logo="images/head/plymouth.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="第一目标100倍"-->
<!--            :realSumMoney="getMoney('第一目标100倍')"-->
<!--            logo="images/head/100bei.png"-->
<!--            :team="['images/binance.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="mb-24">-->
<!--        &lt;!&ndash; Project Card &ndash;&gt;-->
<!--        <CardProject2-->
<!--            title="逢春化绿"-->
<!--            :realSumMoney="getMoney('逢春化绿')"-->
<!--            logo="images/head/default-avatar.png"-->
<!--            :team="['images/binance.png']"-->
<!--        >-->
<!--        </CardProject2>-->
<!--        &lt;!&ndash; / Project Card &ndash;&gt;-->
<!--      </a-col>-->


      <a-col :span="8" class="mb-24" style="height: 223px">
        <a-card class="crm-bar-line header-solid h-full" :bodyStyle="{padding: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
          <a href="#" class="text-center text-muted font-bold">
<!--            <h3 class="font-semibold text-muted mb-0">+</h3>-->
            <h5 class="font-semibold text-muted">更多交易员接入中...</h5>
          </a>
        </a-card>

      </a-col>


    </a-row>
    <!-- / Project Cards -->

  </div>
</template>

<script>
import http from "../utils/http"
import CardProject2 from "../components/Cards/CardProject2"


export default {
  components: {
    CardProject2,
  },
  name: "HotTrader",
  data() {
    return {
      moneyMap: [],
    }
  },

  created() {
    this.fetchMoney()
  },

  methods: {
    // 获取数据
    async fetchMoney() {
      try {
        const response = await http.get('/api/hotbalance/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          this.moneyMap = response.data.data;
        } else {
          this.$message.error({content: response.data.error, key, duration: 2});
        }
      } catch (error) {
        console.error('获取数据失败:', error);
      }
    },

    // 根据id动态获取money
    getMoney(e) {
      const project = this.moneyMap.find(item => item[e] !== undefined);
      return project ? project[e] : 0; // 如果找到，返回值；否则返回 0
    },
  },

  // computed: {
  //   // 动态获取realSumMoney
  //   getMoney(e) {
  //     if (e === 'Bit浪浪') {
  //       return 1000
  //     }
  //   },
  // },

}
</script>

<style scoped>

</style>